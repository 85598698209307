<template>
  <div class="space-y-4 md:space-y-6">
    <template v-if="!enabled">
      <button
        @click.prevent="onToggleEnabled"
        class="inline-flex items-center justify-center w-full px-3 py-2 text-sm font-medium leading-4 bg-indigo-700 border border-transparent rounded-md shadow-sm select-none text-neutral-100 ring-offset-0 focus:ring-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:border-indigo-600 focus:ring-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
      >
        <span v-if="form.busy">
          <fa-icon
            :icon="['fad', 'spinner-third']"
            class="mr-2 -ml-1 animate-spin fa-fw"
            spin
          />Loading
        </span>
        <span v-else>
          <fa-icon icon="share" class="mr-2 -ml-1 fa-fw" />Share
        </span>
      </button>

      <p class="mt-1 text-xs font-semibold text-center text-neutral-400">
        Not shared - <span>Visible to team members only</span>
      </p>
    </template>
    <template v-else>
      <div>
        <label
          for="folderName"
          class="block text-sm font-medium text-neutral-400"
          >Share Link Name</label
        >
        <div class="relative flex mt-1 rounded-md shadow-inner">
          <input
            :disabled="inputDisabled"
            tabindex="0"
            type="text"
            id="item-title"
            :placeholder="title"
            autofocus
            ref="nameinput"
            v-model="title"
            class="relative block w-full bg-black rounded-md shadow-inner peer ellipsis pr-14 placeholder-neutral-400 focus:placeholder-neutral-100 text-neutral-400 focus:text-neutral-100 focus:bg-neutral-900 focus:ring-indigo-500 focus:border-indigo-500 border-neutral-600 focus-within:ring-2 focus-within:border-transparent focus-within:ring-indigo-600"
          />
          <button
            @click.prevent="
              !formHasChanges ? (inputDisabled = !inputDisabled) : saveChanges()
            "
            class="absolute top-0 right-0 inline-flex items-center h-full px-3 py-2 mx-auto text-sm font-medium border-r text-centerborder-l hover:rounded-md hover:border rounded-r-md text-neutral-100 peer-focus:border-l-2 peer-focus:border-indigo-600 border-neutral-600"
            :class="
              formHasChanges
                ? ''
                : ' hover:peer-focus:bg-indigo-600 hover:peer-focus:text-neutral-100 hover:peer-focus:border-l-2 hover:peer-focus:border-indigo-600 hover:bg-neutral-600 hover:border-neutral-400'
            "
          >
            <fa-icon
              :icon="formHasChanges ? 'check' : 'i-cursor'"
              class="fa-fw"
            />
          </button>
        </div>
      </div>

      <div class="inline-flex justify-end w-full">
        <div class="mx-4 mt-6 border-r first:hidden border-neutral-600" />
        <ShareLinkBlock v-bind:share="share" />
      </div>
    </template>
  </div>
</template>
<script>
const ShareLinkBlock = () => import('@components/share/ShareLinkBlock')

export default {
  name: 'share-link-form',

  components: {
    ShareLinkBlock
  },

  props: {
    asset: {
      type: Object,
      required: true
    }
  },

  mounted() {
    this.$store
      .dispatch('shares/loadShareForAsset', { assetId: this.asset.id })
      .then(this.onToggleEnabled())
  },

  data() {
    return {
      showExtra: false,
      title: null,
      enabled: false,
      inputDisabled: true,

      form: new SparkForm({}),

      errors: null
    }
  },

  watch: {
    share: {
      handler() {
        if (this.share !== null) {
          this.title = this.share.title
          this.enabled = this.share.enabled
        }
      },
      immediate: true
    }
  },

  computed: {
    loading() {
      return this.$store.getters['shares/loading']
    },

    loaded() {
      return this.$store.getters['shares/loaded']
    },

    share() {
      return this.$store.getters['shares/getShareByAssetId'](this.asset.id)
    },

    hasShare() {
      return this.share !== null
    },

    formHasChanges() {
      if (this.share === null) return false
      return this.share.title !== this.title
    },

    formReadyToSumbit() {
      return true
    }
  },

  methods: {
    clearChanges() {
      this.title = this.share.title
    },

    saveChanges() {
      this.inputDisabled = true
      let payload = {
        title: this.title
      }
      this.submitChange(payload)
    },

    onToggleEnabled() {
      let payload = {
        enabled: true
      }
      this.submitChange(payload)
    },

    submitChange(payload) {
      this.form.startProcessing()

      if (this.hasShare) {
        this.$store
          .dispatch('shares/updateShareSetting', {
            id: this.share.id,
            payload: payload
          })
          .then((response) => {
            this.form.finishProcessing()
            this.clearChanges()
          })
          .catch((response) => {
            this.form.finishProcessing()
            this.form.setErrors(response.data)
            this.errors = response.data.errors
          })
      } else {
        // Create a new share instead
        payload.asset_id = this.asset.id
        this.$store
          .dispatch('shares/createShare', {
            payload: payload
          })
          .then((result) => {
            this.form.finishProcessing()
            this.clearChanges()
          })
          .catch((response) => {
            this.form.finishProcessing()
            this.form.setErrors(response.data)
            this.errors = response.data.errors
          })
      }
    }
  }
}
</script>
